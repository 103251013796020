import React from 'react'
import {AiOutlineSearch} from 'react-icons/ai'

import'./Body.css'

const Body = () => {
  return (
    <div className='body'>
        <div className='content'>
            <h1>Find the perfect place</h1>
            <p className='search-text'>Search the largest selection of luxury high-rise apartments, multi-family homes and luxury homes.</p>
            <form className='search'>
                <div>
                    <input type='text' placeholder='Enter keyword..' />
                </div>
                <div className='radio'>
                    <input type='radio' checked />
                    <label>Buy</label>
                    <input type='radio' />
                    <label>Rent</label>
                    <button type='submit'><AiOutlineSearch className='icon' /></button>
                </div>
            </form>
        </div>
      
    </div>
  )
}

export default Body
