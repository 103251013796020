import React from 'react';
import Navbar from './components/Navbar';
import Body from './components/Body';
import Sample from './components/sample/Sample';
import Feature from './components/features/Feature';
import Footer from './components/footer/Footer';


function App() {
  return (
    <>
    <Navbar/>
    <Body/>
    <Sample />
    <Feature />
    <Footer />
    </>
    
  );
}

export default App;
